


export class EstadoDocumento {
    constructor(public codigo?: string, public descripcion?: string,
        public color?: string,
        public icono?: string,


    ) {
    }
    public static fromData(data: any): EstadoDocumento {
        if (!data) return null;
        const o: EstadoDocumento = new EstadoDocumento(

            data.codigo,
            data.descripcion,
            data.color,
            data.icono,

        );

        return o;

    }

}