import { Parametrico } from 'src/app/common/model/Parametrico';




export class EstadoReserva extends Parametrico {
    public static CANCELADO: string = 'C';
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        esDefault: boolean = false,
        public color?: string,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso, null, esDefault)
    }
    public tipoParametro(): string {
        return "$MODELNAMELOW";
    }
    public static fromData(data: any): EstadoReserva {
        if (!data) return null;
        const o: EstadoReserva = new EstadoReserva(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.esDefault,
            data.color,

        );

        return o;

    }

}