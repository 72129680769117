import { Descriptivo } from './../../model/Descriptivo';

export class Parametrico extends Descriptivo {

    public static UNIDAD_PRODUCTO_ID = "unidadproducto";
    static NUEVO: Parametrico = new Parametrico(null, "NN", "Nuevo", true, true, 0, 'fa fa-plus');
    public searchStr: string;
    constructor(public id?: number, codigo?: string, descripcion?: string,
        public habilitado: boolean = true,
        public esSistema: boolean = false,
        public peso: number = 99,
        public icon?: string,
        public esDefault: boolean = false) {
        super(codigo, descripcion);

    }
    entityName(): string {
        return this.tipoParametro();
    }

    public tipoParametro(): string {
        return "parametrico";
    }
    public static fromData(data: any) {
        if (!data) return null;

        let o: Parametrico = new Parametrico(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,
            data.icono,
            data.esBoolean
        );
        return o;
    }

    public get iconoClass() {
        return this.icon;
    }

}