import { Descriptivo } from './Descriptivo';

export class Rol extends Descriptivo {

    public static ADMIN_CODE = 'ROLE_ADMIN';
    public static EMPLEADO_CODE = 'ROLE_EMPLEADO';
    public static VISUALIZADOR_CODE = 'ROLE_VISUALIZADOR';

    constructor(id?: number, codigo?: string, descripcion?: string) {
        super(codigo, descripcion, id);
    }

    public static fromData(data: any): Rol {
        if (!data) return null;
        let o: Rol = new Rol(
            data.id,
            data.codigo,
            data.descripcion
        );

        return o;

    }
}