import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { ToastController } from '@ionic/angular'
import { Storage } from '@ionic/storage'
import * as moment from 'moment'
import { BehaviorSubject } from 'rxjs'
import { filter, first } from 'rxjs/operators'
import { EstadoReserva } from 'src/app/model/EstadoReserva'
import { AuthService } from 'src/app/services/auth.service'
import { Reserva } from '../model/Reserva'
import { environment } from './../../environments/environment'
import { Descriptivo } from './../model/Descriptivo'
import { Novedad } from './../model/Novedad'
import { Ocupacion } from './../model/Ocupacion'
import { BaseService } from './base.service'
import { IActualizable, NovedadService } from './novedades.service'
import { BloqueoFecha } from '../model/BloqueoFecha'
@Injectable({
    providedIn: 'root',
})
export class ReservaService extends BaseService implements IActualizable {
    public misReservasObs: BehaviorSubject<Reserva[]> = new BehaviorSubject([])
    public reservaEditadaObs: BehaviorSubject<Reserva> = new BehaviorSubject(null)
    private _misReservas: Reserva[]
    private initializing: Promise<any>
    private currentUser
    private ready: BehaviorSubject<boolean> = new BehaviorSubject(false)
    public updateReserva(r: Reserva) {
        const v = this.misReservas.filter((d) => d.id == r.id)[0]
        Object.assign(v, r)
    }
    public quitarReserva(r: Reserva) {
        this.misReservas = this.misReservas.filter((rr) => rr.id != r.id)
    }

    private _reservaEditada: Reserva
    public get reservaEditada(): Reserva {
        return this._reservaEditada
    }
    public set reservaEditada(v: Reserva) {
        if (this._reservaEditada != v) {
            this._reservaEditada = v
            this.reservaEditadaObs.next(v)
        }
    }
    next(n: Novedad) {
        if (n.idEntidad) {
            return this.getById(n.idEntidad).then(async (p) => {
                const toupdate = this._misReservas.filter((pp) => pp.id == p.id)[0]
                Object.assign(toupdate, p)
                this.storage.set(environment.tokenKey + 'reservas', this._misReservas)
                this.misReservasObs.next(this._misReservas)
                const a = await this.toastController.create({
                    header: 'Reserva N° ' + p.id + ' actualizada',
                    duration: 2000,
                })
                a.present()
            })
        }
        return Promise.resolve(true)
    }
    destroy() {}
    public get misReservas(): Reserva[] {
        return this._misReservas
    }
    public set misReservas(v: Reserva[]) {
        this._misReservas = v.sort((a, b) => {
            const aD = moment(a.fecha)
            const bD = moment(b.fecha)
            if (aD.isSame(bD, 'date')) {
                return a.horario > b.horario ? 1 : -1
            } else {
                return aD.isBefore(bD) ? -1 : 1
            }
        })
        this.misReservasObs.next(this._misReservas)
        this.storage.set(environment.tokenKey + 'reservas', this.misReservas)
    }

    public cancelarReserva(r: Reserva): Promise<Reserva> {
        return this.http
            .post(this.getBaseURL('cancelar'), { idEntidad: r.id })
            .toPromise()
            .then((r) => {
                this.reservaEditada = null
                return Reserva.fromData(r)
            })
    }
    getById(idEntidad: number): Promise<Reserva> {
        return this.http
            .get(`${this.getBaseURL()}${idEntidad}`)
            .toPromise()
            .then((p) => {
                return Reserva.fromData(p)
            })
            .catch((e) => {
                return Promise.reject()
            })
    }
    public agregarReserva(r: Reserva) {
        this.misReservas.push(r)
    }
    registrarReserva(reserva: Reserva): Promise<Reserva> {
        return this.http
            .post(this.getBaseURL('api'), reserva)
            .toPromise()
            .then((r) => {
                let v = Reserva.fromData(r)
                this.novedadesService.registrarObservador('reserva', this, v.id)
                this._misReservas.push(v)
                this.misReservas = this._misReservas
                return v
            })
    }
    public baseName(): string {
        return 'reserva'
    }
    constructor(
        private storage: Storage,
        private novedadesService: NovedadService,
        private toastController: ToastController,
        public authService: AuthService,
        http: HttpClient
    ) {
        super(http)
        this.init()
        this.authService.getAutenticateState().subscribe((resp) => {
            // this.borrarReservas()
        })
    }

    public async init() {
        if (this.initializing) return this.initializing
        this.initializing = this.storage.ready().then(async (s) => {
            this.storage.get(environment.tokenKey + 'reservas').then((res) => {
                if (res) {
                    this.misReservas = res.map((r) => Reserva.fromData(r))
                } else {
                    this.misReservas = []
                }
                this.ready.next(true)
            })
        })
        this.authService.onLogout.subscribe(() => this.borrarReservas())
        this.authService
            .getUserObs()
            .pipe(filter((u) => u != undefined && this.currentUser?.id != u?.id))
            .subscribe((u) => {
                this.currentUser = u
                u.username != 'appuser' &&
                    this.http
                        .get(`${this.getBaseURL()}getReservas`)
                        .toPromise()
                        .then((r: Reserva[]) => {
                            this.misReservas = r.map((res) => Reserva.fromData(res))
                            this.ready.next(true)
                        })
                        .catch((e) => {
                            this.misReservas = []
                        })
            })
        this.initializing.finally(() => (this.initializing = null))
        return this.initializing
    }
    getOcupadas(start: Date, end: Date, local: Descriptivo): Promise<any[]> {
        return this.http
            .post(this.getBaseURL('api/getOcupacion'), { fechaDesde: start, fechaHasta: end, local: local })
            .toPromise()
            .then(
                (r: any) => {
                    return r.map((v) => Ocupacion.fromData(v))
                },
                (e) => this.handleError(e)
            )
    }
    getBloqueosByLocal( local: Descriptivo): Promise<BloqueoFecha[]> {
        return this.http
            .post(this.getBaseURL('api/getBloqueos'), { local: local })
            .toPromise()
            .then(
                (r: any) => {
                    return r.map((v) => BloqueoFecha.fromData(v))
                },
                (e) => this.handleError(e)
            )
    }

    private _performUpdate() {
        return this.http
            .post(`${this.getApiURL()}reserva/api/updateEstados`, {
                idsReservas: this._misReservas.filter((p) => p.noFinalizado()).map((p) => p.id),
            })
            .toPromise()
            .then((r: any) => {
                r.forEach((p) => {
                    var o = this._misReservas.filter((pp) => p.id == pp.id)[0]
                    if (o) o.estado = EstadoReserva.fromData(p.estado)
                })
                this.storage.set(environment.tokenKey + 'reservas', this._misReservas)
                this.misReservasObs.next(this._misReservas)
            })
    }
    actualizarEstados() {
        return this.ready
            .pipe(
                filter((e) => e == true),
                first()
            )
            .toPromise()
            .then((r) => {
                return this._performUpdate()
            })
    }

    borrarReservas() {
        this.misReservas = []
        this.storage.remove(environment.tokenKey + 'reservas')
    }
}
