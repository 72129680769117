import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-modal-pedido',
	templateUrl: './modal-pedido.component.html',
	styleUrls: ['./modal-pedido.component.scss'],
})
export class ModalPedidoComponent implements OnInit {

	hora: any;
	pedido: any;

	constructor(
		private modalController: ModalController
	) { }

	ngOnInit() { }

	dismiss() {
		this.modalController.dismiss();
	}

}
