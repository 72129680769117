export class TurnoReserva {
    constructor(
        public dias: number[] = [], //0 a 6
        public horarios: string[] = [],
        public capacidad: number = 0,
        public duracion: number = 60

    ) {

    }

    public static fromData(data) {
        if (!data) return;

        return new TurnoReserva(
            data.dias,
            data.horarios,
            data.capacidad,
            data.duracion
        )
    }

    public esDia(dia) {
        return this.dias.some(d => (d + 1) == dia);
    }
}