import * as moment from "moment";
import { Descriptivo } from "./Descriptivo";



export class BloqueoFecha {
    constructor(
        public id?: number,
        public fecha?: Date,
        public motivo?: string,
        public responsable?: Descriptivo,
        public local?:Descriptivo

    ) {
    }
    public static fromData(data: any): BloqueoFecha {
        if (!data) return null;
        const o: BloqueoFecha = new BloqueoFecha(

            data.id,
            data.fecha ? new Date(data.fecha) : null,
            data.motivo,
            Descriptivo.fromData(data.responsable),
            Descriptivo.fromData(data.local)
        );

        return o;

    }

    public aplica =(fecha:Date) =>{
        return moment(this.fecha).isSame(fecha,'date');
    }

}