import { Parametrico } from 'src/app/common/model/Parametrico';



export class TipoProducto extends Parametrico {
    public static REVENTA = "REV";
    public static INSUMO = "INS";
    public static FRACCIONABLE = "FRA";
    public static ELABORADO = "ELAB";
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "TipoProductoLOW";
    }
    public static fromData(data: any): TipoProducto {
        if (!data) return null;
        const o: TipoProducto = new TipoProducto(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

        );

        return o;

    }

}