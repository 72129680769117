import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { Local } from './../model/Local';
import { BaseService } from './base.service';
import { BufferedService } from './bufferd.service';
import { NovedadService } from './novedades.service';

@Injectable({
    providedIn: 'root'
})
export class LocalService extends BaseService {


    public localSeleccionado: BehaviorSubject<Local> = new BehaviorSubject(null);
    public locacionSeleccionada: BehaviorSubject<Local> = new BehaviorSubject(null);
    private _admiteReservas = false;
    public admiteReservasSub: BehaviorSubject<boolean> = new BehaviorSubject(this._admiteReservas);
    public localesConPedido: BehaviorSubject<Local[]> = new BehaviorSubject(null);
    public ready: BehaviorSubject<boolean> = new BehaviorSubject(false);
    protected getName() {
        return "local";
    }
    getByIdentificador(localIdentificador: string): Promise<Local>{
        return this.http
            .get(
                `${this.getApiURL()}local/api/getByIdentificador/?identificador=${localIdentificador}`
            ).pipe(map(r =>Local.fromData(r))).toPromise()
    }
    public hayLocal() {
        return this._local != undefined;
    }
    public cleanCache() {

        this.local = null;
        this.locacion = null;
    }
    public get admiteReservas(): boolean {
        return this._admiteReservas;
    }
    public set admiteReservas(v: boolean) {
        this._admiteReservas = v;
        this.admiteReservasSub.next(v);
    }
    constructor(http: HttpClient, private novedadesService: NovedadService, private storage: Storage) {
        super( http)

    }

    public getLocales(searchStr: String): Promise<Local[]>{
        return this.http.get(`${this.getApiURL()}local/api?searchStr=${searchStr}`).pipe(map((locales: any) => {
            return locales.map(l => Local.fromData(l));
        })).toPromise()
    }
    public getById(id: number): Promise<Local>{
       return  this.http.get(`${this.getApiURL()}local/api/${id}`).pipe(map(l => {return Local.fromData(l)
        })).toPromise()
    }
    public seleccionarLocal(idLocal: number) {
        if (this._local?.id != idLocal) {
            return this.getById(idLocal).then(l => {

                if (l && l.id != this.local?.id) {

                    this.novedadesService.currentLocal = l.id;
                    //this.novedadesService.registrarObservador(this.baseName(), this, l.id);
                }
                this.local = l;
                this.admiteReservas = this.local.admiteReserva;
                return this.local;
            })
        } else {
            this.admiteReservas =false;
            return Promise.resolve(this._local);
        }
    }
    public seleccionarLocacion(idLocal: number) {
        if (this._locacion?.id != idLocal) {
            return this.getById(idLocal).then(l => {
                this.locacion = l;
                //this.novedadesService.registrarObservador(this.baseName(), this, l.id);
                return this.locacion;
            })
        } else {
            return Promise.resolve(this._locacion);
        }
    }

    public getSeleccionado(): Promise<Local> {
        return this.local ? Promise.resolve(this.local) : this.localSeleccionado.pipe(filter(l => l != undefined)).toPromise();
    }
    public async init() {

        let l = await this.storage.get(environment.tokenKey + "_local-elegido");
        let locacion = await this.storage.get(environment.tokenKey + "_locacion-elegido");

        const proms = [];
        if (l && !this.local) {
            proms.push(this.seleccionarLocal(l));
        }
        if (locacion && !this.locacion) {
            proms.push(this.seleccionarLocacion(l));
        }
        Promise.all(proms).then(r => {
            this.ready.next(true);
            this.ready.complete();
        })
    }
    public baseName() {
        return "local"
    }
    public parse(data): Local {
        return Local.fromData(data);
    }

    private _locacion: Local;
    public get locacion(): Local {
        return this._locacion;
    }
    public set locacion(v: Local) {
        if (this._locacion != v) {
            this._locacion = v;

            this.locacionSeleccionada.next(v);
            if (this._locacion) {
                this.storage.set(environment.tokenKey + "_locacion-elegido", this._locacion.id)
            }
            else {
                this.storage.remove(environment.tokenKey + "_locacion-elegido");
            }
        }
    }

    private _local: Local;
    public get local(): Local {
        return this._local;
    }
    public set local(v: Local) {
        if (this._local != v) {
            this._local = v;
            this.novedadesService.currentLocal = v?.id;
            this.localSeleccionado.next(v);
            if (this._local) {
                this.storage.set(environment.tokenKey + "_local-elegido", this._local.id)
            }
            else {
                this.storage.remove(environment.tokenKey + "_local-elegido");
            }
        }

    }

}
