import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tags'
})
export class TagsPipe implements PipeTransform {

  transform(text: string): string {
    if ( (text === null || text === '')) {
      return '';
    } else {
      return text.replace(/&nbsp;/gm, ' ').replace(/<(?:.|\n)*?>/gm, '')
    }
  }

}
