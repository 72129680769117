import { HttpClient } from '@angular/common/http';
import { environment } from './../../environments/environment';
export abstract class BaseService {

    constructor(protected http: HttpClient) {

    }
    protected handleOk = (response: any): string => {
        let res = response;
        return res.mensaje;
    }
    protected handleError(error: any): Promise<any> {

        return Promise.reject(error.error || error);
    }

    public abstract baseName(): string;

    getApiURL() {
        return environment.apiUrl;
    }
    public getBaseURL(path?: string): string {
        return this.getApiURL() + this.baseName() + "/" + (path ? path : '');
    }

}