import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'slider-item',
  templateUrl: './slider-item.component.html',
  styleUrls: ['./slider-item.component.scss'],
})
export class SliderItemComponent implements OnInit {

  constructor() { }

  @Input()	item: any = [];

  ngOnInit() {}

}
